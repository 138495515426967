@mixin bg-variant($class, $color, $color-dark: "") {
  #{$class} {
    background-color: $color !important;
  }

  @if ($color-dark != "") {
    a#{$class} {
      // active, focus, hover
        @include active-focus-hover {
          background-color: $color-dark !important;
        }
    }
  }
}
