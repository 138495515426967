//
// todo: tabs with icons
//

.nav-tabs {
  box-shadow: inset 0 -1px 0 $nav-tab-border-color;

  .nav-link {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: $nav-tab-link-font-size;
    font-weight: $nav-tab-link-font-weight;
    height: 100%;
    justify-content: center;
    line-height: 1;
    min-height: $nav-tab-link-height;
    opacity: $nav-tab-link-opacity;
    padding: (($nav-tab-link-height - $nav-tab-link-font-size * 2) / 2) $nav-tab-link-padding-x;
    position: relative;
    text-transform: uppercase;
    @include transition-standard(background-color, opacity);

    &::before {
      background-color: $nav-tab-indicator-bg;
      content: "";
      display: block;
      height: $nav-tab-indicator-height;
      opacity: 0;
      position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      @include transition-standard(opacity);
    }

    @include media-breakpoint-up(lg) {
      padding-right: $nav-tab-link-padding-x-lg;
      padding-left: $nav-tab-link-padding-x-lg;
    }

    // active, focus, hover
      @include active-focus-hover {
        background-color: $nav-link-bg-active;
      }

      &:active,
      &.active {
        opacity: $nav-tab-link-opacity-visible;
      }

      &.active {
        &::before {
          opacity: $nav-tab-link-opacity-visible;
        }
      }

    // disabled
      &.disabled {
        background-color: transparent;
        color: $nav-link-color-disabled;
        opacity: $nav-tab-link-opacity-visible;
      }
  }
}

// variation
  .nav-tabs {
    &.border-0,
    &.border-bottom-0 {
      box-shadow: none;
    }

    &.nav-justified {
      .nav-link {
        min-width: $nav-tab-link-min-width;

        @include media-breakpoint-up(lg) {
          min-width: $nav-tab-link-min-width-lg;
        }
      }
    }

    &.nav-scrollable {
      overflow-x: auto;
      overflow-y: visible;
      -webkit-overflow-scrolling: touch;

      .nav-link {
        max-width: $nav-tab-link-max-width;
        min-width: $nav-tab-link-min-width;

        @include media-breakpoint-up(lg) {
          min-width: $nav-tab-link-min-width-lg;
        }
      }
    }
  }

  .nav-tabs-material {
    position: relative;

    .nav-link::before {
      transition: none;
    }

    .nav-tabs-indicator {
      background-color: $nav-tab-indicator-bg;
      height: $nav-tab-indicator-height;
      display: none;
      position: absolute;
        bottom: 0;

      &.show {
        display: block;
      }
    }

    // animation
      &.animate {
        .nav-link::before {
          opacity: 0;
        }

        .nav-tabs-indicator {
          transition-duration: $transition-duration;
          transition-property: left, right;
          transition-timing-function: $transition-timing-function-standard;
        }
      }
  }
