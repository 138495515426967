//
// margin and padding
//
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint);

      .m#{$infix}-auto {
        margin: auto !important;
      }

      .mt#{$infix}-auto {
        margin-top: auto !important;
      }

      .mr#{$infix}-auto {
        margin-right: auto !important;
      }

      .mb#{$infix}-auto {
        margin-bottom: auto !important;
      }

      .ml#{$infix}-auto {
        margin-left: auto !important;
      }

      .mx#{$infix}-auto {
        margin-right: auto !important;
        margin-left: auto !important;
      }

      .my#{$infix}-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }

      @each $prop, $prop-abbr in (margin: m, padding: p) {
        @each $size, $values in $spacers {
          $length-x: map-get($values, x);
          $length-y: map-get($values, y);

          .#{$prop-abbr}#{$infix}-#{$size} {
            #{$prop}: $length-y $length-x !important;
          }

          .#{$prop-abbr}t#{$infix}-#{$size} {
            #{$prop}-top: $length-y !important;
          }

          .#{$prop-abbr}r#{$infix}-#{$size} {
            #{$prop}-right: $length-x !important;
          }

          .#{$prop-abbr}b#{$infix}-#{$size} {
            #{$prop}-bottom: $length-y !important;
          }

          .#{$prop-abbr}l#{$infix}-#{$size} {
            #{$prop}-left: $length-x !important;
          }

          .#{$prop-abbr}x#{$infix}-#{$size} {
            #{$prop}-right: $length-x !important;
            #{$prop}-left: $length-x !important;
          }

          .#{$prop-abbr}y#{$infix}-#{$size} {
            #{$prop}-top: $length-y !important;
            #{$prop}-bottom: $length-y !important;
          }
        }
      }
    }
  }
