.dialog,
.modal {
  display: none;
  overflow: hidden;
  outline: 0;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  text-align: center;
  white-space: nowrap;
  z-index: map-get($dialog-elevation-shadow, elevation);

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
    width: 1px;
  }
}

.modal-open {
  overflow: hidden;

  .dialog,
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

//
// backdrop
//
  .modal-backdrop {
    background-color: $dialog-backdrop-bg;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    z-index: (map-get($dialog-elevation-shadow, elevation) - 1);

    @media only screen and (min-width: 1114px){
      position: fixed;
    }

    &.fade {
      transform: none;
    }
  }

//
// dialog
//
  .dialog-dialog,
  .modal-dialog {
    display: inline-block;
    margin: $dialog-margin-y $dialog-margin-x;
    max-width: $dialog-width;
    position: relative;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: calc(100% - #{$dialog-margin-x} * 2);
  }

  // size
    .dialog-lg,
    .modal-lg {
      max-width: $dialog-width-lg;
    }

    .dialog-sm,
    .modal-sm {
      max-width: $dialog-width-sm;
    }

//
// misc
//
  .dialog-body,
  .modal-body {
    flex: 1 1 auto;
    padding: $dialog-inner-padding;
    position: relative;

    > :last-child {
      margin-bottom: 0;
    }
    button.close span {
      color: #fff;
    }
    .no-wifi-container{
      text-align: center;
      padding: 0;
    }
    img.no-wifi {
        width:80%;
    }
    h3.pink{
      color: $palette-pink-500;
    }
    .not-now{
      color: $palette-pink-500;
      margin: 0 0 0 1rem;
      border: none;
      background: #fff;
      cursor: pointer;
      padding: 1.5rem;
      font-size: 1rem !important;
    }
  }

  .dialog-content,
  .modal-content {
    background-clip: padding-box;
    background-color: $dialog-content-bg;
    box-shadow: map-get($dialog-elevation-shadow, shadow);
    display: flex;
    flex-direction: column;
    outline: 0;
    position: relative;
    vertical-align: baseline;
    @include border-radius($border-radius);
  }

  .dialog-footer,
  .modal-footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: $dialog-footer-spacer-y ($dialog-footer-spacer-x / 2);
    padding-left: ($dialog-inner-padding - $dialog-footer-spacer-x / 2);

    > * {
      margin-right: ($dialog-footer-spacer-x / 2);
      margin-left: ($dialog-footer-spacer-x / 2);
    }

    .btn {
      min-width: $dialog-footer-btn-min-width;
      padding-right: $dialog-footer-spacer-x;
      padding-left: $dialog-footer-spacer-x;
    }
  }

  .dialog-header,
  .modal-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    padding-bottom: 0;
    background: $palette-blue-500;
    color: #fff;

    &:last-child {
      padding-bottom: $dialog-inner-padding;
    }
    button.close span {
      color: #fff;
    }
  }

  .dialog-title,
  .modal-title {
    margin: 0;
    @include typography-title;
  }

//
// scrollbar measure
//
  .modal-scrollbar-measure {
    height: 50px;
    overflow: scroll;
    position: absolute;
      top: -99999px;
    width: 50px;
  }

  .wrapper-modal-certificates button {
    color: #8D8E90 !important;
  }