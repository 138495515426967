//
// material design guidelines specifically discouragae the usage of tooltips with rich information
// (https://material.google.com/components/tooltips.html#tooltips-usage)
//

.popover {
  background-clip: padding-box;
  background-color: $dialog-content-bg;
  border: 1px solid transparent;
  box-shadow: map-get($dialog-elevation-shadow, shadow);
  display: block;
  font-size: $font-size-old-base;
  max-width: $dialog-width-sm;
  position: absolute;
    top: 0;
    left: 0;
  z-index: map-get($dialog-elevation-shadow, elevation);
  @include border-radius($border-radius);
  @include reset-text;

  &,
  &.fade,
  &.fade.show {
    transition-property: opacity;
  }

  &.bs-tether-element-attached-bottom,
  &.popover-top {
    margin-top: ($tooltip-margin * -1);
  }

  &.bs-tether-element-attached-left,
  &.popover-right {
    margin-left: $tooltip-margin;
  }

  &.bs-tether-element-attached-right,
  &.popover-left {
    margin-left: ($tooltip-margin * -1);
  }

  &.bs-tether-element-attached-top,
  &.popover-bottom {
    margin-top: $tooltip-margin;
  }
}

.popover-content {
  padding: $dialog-inner-padding;
}

.popover-title {
  margin: 0;
  padding: $dialog-inner-padding;
  padding-bottom: 0;
  @include typography-title;
  .close {
    &:focus {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  &:empty {
    display: none;
  }
}

// desktop
  @include media-breakpoint-up(md) {
    .popover {
      &.bs-tether-element-attached-bottom,
      &.popover-top {
        margin-top: ($tooltip-margin-md-up * -1);
      }

      &.bs-tether-element-attached-left,
      &.popover-right {
        margin-left: $tooltip-margin-md-up;
      }

      &.bs-tether-element-attached-right,
      &.popover-left {
        margin-left: ($tooltip-margin-md-up * -1);
      }

      &.bs-tether-element-attached-top,
      &.popover-bottom {
        margin-top: $tooltip-margin-md-up;
      }
    }
  }
