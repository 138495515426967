@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    // align content
      .align-content#{$infix}-around {
        align-content: space-around !important;
      }

      .align-content#{$infix}-between {
        align-content: space-between !important;
      }

      .align-content#{$infix}-center {
        align-content: center !important;
      }

      .align-content#{$infix}-end {
        align-content: flex-end !important;
      }

      .align-content#{$infix}-start {
        align-content: flex-start !important;
      }

      .align-content#{$infix}-stretch {
        align-content: stretch !important;
      }

    // align item
      .align-items#{$infix}-baseline {
        align-items: baseline !important;
      }

      .align-items#{$infix}-center {
        align-items: center !important;
      }

      .align-items#{$infix}-start {
        align-items: flex-start !important;
      }

      .align-items#{$infix}-end {
        align-items: flex-end !important;
      }

      .align-items#{$infix}-stretch {
        align-items: stretch !important;
      }

    // align self
      .align-self#{$infix}-auto {
        align-self: auto !important;
      }

      .align-self#{$infix}-baseline {
        align-self: baseline !important;
      }

      .align-self#{$infix}-center {
        align-self: center !important;
      }

      .align-self#{$infix}-end {
        align-self: flex-end !important;
      }

      .align-self#{$infix}-start {
        align-self: flex-start !important;
      }

      .align-self#{$infix}-stretch {
        align-self: stretch !important;
      }

    // direction
      .flex#{$infix}-column {
        flex-direction: column !important;
      }

      .flex#{$infix}-column-reverse {
        flex-direction: column-reverse !important;
      }

      .flex#{$infix}-row {
        flex-direction: row !important;
      }

      .flex#{$infix}-row-reverse {
        flex-direction: row-reverse !important;
      }

    // justify content
      .justify-content#{$infix}-around {
        justify-content: space-around !important;
      }

      .justify-content#{$infix}-between {
        justify-content: space-between !important;
      }

      .justify-content#{$infix}-center {
        justify-content: center !important;
      }

      .justify-content#{$infix}-end {
        justify-content: flex-end !important;
      }

      .justify-content#{$infix}-start {
        justify-content: flex-start !important;
      }

    // order
      .flex#{$infix}-first {
        order: -1; 
      }

      .flex#{$infix}-last {
        order: 1;
      }

      .flex#{$infix}-unordered {
        order: 0;
      }

    // wrap
      .flex#{$infix}-nowrap {
        flex-wrap: nowrap !important;
      }

      .flex#{$infix}-wrap {
        flex-wrap: wrap !important;
      }

      .flex#{$infix}-wrap-reverse {
        flex-wrap: wrap-reverse !important;
      }
  }
}

.content-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* IE11 workaround */
.flex-wrap {
  min-width: 0;
  white-space: normal;
}
