// alert
  $alert-link-font-weight: $font-weight-medium !default;

// body
  $body-bg:    $palette-grey-a400 !default;
  $body-color: $black-primary !default;

// breadcrumb
  $breadcrumb-bg:                $offwhite !default;
  $breadcrumb-divider:           url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.54' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $breadcrumb-height:            3.5rem !default;
  $breadcrumb-item-color:        $black-secondary !default;
  $breadcrumb-item-color-active: $black-primary !default;
  $breadcrumb-item-padding:      $spacer-sm-x !default;
  $breadcrumb-margin-bottom:     $spacer-md-y !default;
  $breadcrumb-padding-x:         $spacer-md-x !default;
  $breadcrumb-padding-y:         0.625rem !default;

// button group
  $btn-group-bg:           $offwhite !default;
  $btn-group-padding-x:    $spacer-sm-x !default;
  $btn-group-padding-y:    $spacer-sm-y !default;
  $btn-toolbar-divider-bg: $black-divider !default;

// caret
  $caret-bg:         "data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.54' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E" !default;
  $caret-bg-inverse: "data:image/svg+xml;charset=utf8,%3Csvg fill='%23ffffff' fill-opacity='0.7' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E" !default;
  $caret-width:      0.4em !default;

// carousel
  $carousel-caption-color:        $white-primary !default;
  $carousel-caption-width:        50% !default;
  $carousel-control-icon-opacity: 0.38 !default;
  $carousel-control-next-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $carousel-control-prev-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $carousel-indicator-color:      $black-divider !default;
  $carousel-indicator-size:       0.5rem !default;

// close
  $close-color:        $black-hint !default;
  $close-color-active: $black-primary !default;
  $close-font-size:    $material-icon-size !default;

// code
  $code-bg:                   #f7f7f9 !default;
  $code-color:                #bd4147 !default;
  $code-font-size:            87.5% !default;
  $code-padding:              0.2rem 0.4rem !default;

  $kbd-bg:                    #333333 !default;
  $kbd-color:                 #ffffff !default;

  $pre-bg:                    #f7f7f9 !default;
  $pre-color:                 #373a3c !default;
  $pre-margin-bottom:         $spacer-md-y !default;
  $pre-scrollable-max-height: 340px !default;

// cursor
  $cursor-disabled: not-allowed !default;

// form
  $custom-file-button-bg:              url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z'/%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3C/svg%3E") !default;
  $custom-file-button-opacity:         0.38 !default;
  $custom-file-button-opacity-visible: 0.54 !default;
  $custom-file-width:                  14rem !default;
  $custom-file-text:                   (en: "Choose file...") !default;

  $form-check-inline-margin-x:         $spacer-sm-x !default;
  $form-check-input-margin-x:          $spacer-xs-x !default;

  $form-group-label-font-size:         $font-size-caption !default;
  $form-group-margin-bottom:           $spacer-md-y !default;

  $input-group-addon-margin-x:         $spacer-md-x !default;

// jumbotron
  $jumbotron-bg:    $brand-color !default;
  $jumbotron-color: $brand-text-color !default;

// link
  $link-color:             $brand-color !default;
  $link-color-active:      $brand-color-dark !default;
  $link-decoration:        none !default;
  $link-decoration-active: none !default;

// media
  $media-alignment-padding-x:   $spacer-md-x !default;
  $media-heading-margin-bottom: $spacer-xs-y !default;
  $media-margin-bottom:         $spacer-md-y !default;

// misc
  $abbr-border-color:        $black-divider !default;

  $dt-font-weight:           $font-weight-medium !default;

  $figure-caption-color:     $black-hint !default;
  $figure-img-margin-bottom: $spacer-sm-y !default;

  $headings-color:           inherit !default;
  $headings-font-family:     inherit !default;
  $headings-margin-bottom:   $spacer-sm-y !default;

  $hr-border-color:          $black-divider !default;
  $hr-border-width:          1px !default;

  $list-inline-item-spacer:  $spacer-sm-x !default;

  $mark-bg:                  $palette-yellow-500 !default;
  $mark-padding:             0.2em !default;

  $small-font-size:          80% !default;

  $table-caption-color:      $black-hint !default;

  $thumbnail-box-shadow:     $shadow-2dp !default;

// nav
  $nav-inline-item-spacer:  $spacer-md-x !default;
  $nav-link-bg-active:      $black-divider !default;
  $nav-link-color:          $black-secondary !default;
  $nav-link-color-active:   $black-primary !default;
  $nav-link-color-disabled: $black-hint !default;
  $nav-link-padding:        $spacer-sm-y $spacer-md-x !default;

// pagination
  $pagination-bg:        $btn-group-bg !default;
  $pagination-padding-x: $btn-group-padding-x !default;
  $pagination-padding-y: $btn-group-padding-y !default;

// typography
  $blockquote-border-color: $brand-color !default;
  $blockquote-border-width: 0.3125rem !default;
  $blockquote-small-color:  $black-hint !default;

  $text-muted:              $black-hint !default;
  $text-muted-active:       $black-secondary !default;
