@mixin active-focus-hover {
  @if $enable-hover-media-query {
    &:active,
    &:focus {
      @content
    }

    @include hover {
      @content
    }
  } @else {
    &:active,
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin focus-hover {
  @if $enable-hover-media-query {
    &:focus {
      @content
    }

    @include hover {
      @content
    }
  } @else {
    &:focus,
    &:hover {
      @content
    }
  }
}

@mixin hover {
  @if $enable-hover-media-query {
    @media (hover: hover) {
      &:hover {
        @content
      }
    }
  } @else {
    &:hover {
      @content
    }
  }
}

@mixin plain-active-focus-hover {
  @if $enable-hover-media-query {
    &,
    &:active,
    &:focus {
      @content
    }

    @include hover {
      @content
    }
  } @else {
    &,
    &:active,
    &:focus,
    &:hover {
      @content
    }
  }
}
