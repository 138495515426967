.textarea-autosize {
  height: $textfield-height;
  min-height: $textfield-height;
  resize: none;

  .floating-label > & {
    height: ($floating-label-height - $floating-label-padding-top);
    min-height: ($floating-label-height - $floating-label-padding-top);
  }
}

// size
  .form-control-lg.textarea-autosize {
    height: $textfield-height-lg;
    min-height: $textfield-height-lg;

    .floating-label > & {
      height: ($floating-label-height-lg - $floating-label-padding-top-lg);
      min-height: ($floating-label-height-lg - $floating-label-padding-top-lg);
    }
  }

  .form-control-sm.textarea-autosize {
    height: $textfield-height-sm;
    min-height: $textfield-height-sm;

    .floating-label > & {
      height: ($floating-label-height-sm - $floating-label-padding-top-sm);
      min-height: ($floating-label-height-sm - $floating-label-padding-top-sm);
    }
  }
