@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    .float#{$infix}-left {
      @include pull-left;
    }

    .float#{$infix}-none {
      float: none !important;
    }

    .float#{$infix}-right {
      @include pull-right;
    }
  }
}
