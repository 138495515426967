//
// todo: `.table-inverse`, `.table-reflow` and `.table-sm`
//

.table {
  border: 0;
  margin-bottom: $table-margin-bottom;
  max-width: 100%;
  width: 100%;

  td,
  th {
    border-top: $table-border-width solid $table-border-color;
    padding-right: $table-cell-padding-x;
    padding-left: $table-cell-padding-x;
    vertical-align: top;

    &:first-child {
      padding-left: $table-cell-padding-x-alt;
    }

    &:last-child {
      padding-right: $table-cell-padding-x-alt;
    }
  }

  tbody {
    td,
    th {
      color: $table-tbody-color;
      font-size: $table-tbody-font-size;
      font-weight: $table-tbody-font-weight;
      height: $table-tbody-cell-height;
      padding-top: $table-tbody-padding-y;
      padding-bottom: $table-tbody-padding-y;
    }
  }

  tfoot {
    td,
    th {
      color: $table-tfoot-color;
      font-size: $table-tfoot-font-size;
      font-weight: $table-tfoot-font-weight;
      height: $table-tfoot-cell-height;
      padding-top: $table-tfoot-padding-y;
      padding-bottom: $table-tfoot-padding-y;
    }
  }

  thead {
    td,
    th {
      color: $table-thead-color;
      font-size: $table-thead-font-size;
      font-weight: $table-thead-font-weight;
      height: $table-thead-cell-height;
      padding-top: $table-thead-padding-y;
      padding-bottom: $table-thead-padding-y;
    }
  }

  > tbody:first-child > tr:first-child,
  > tfoot:first-child > tr:first-child,
  > thead:first-child > tr:first-child {
    td,
    th {
      border-top: 0;
    }
  }

  .table {
    border-top: $table-border-width solid $table-border-color;
  }
}

//
// additional versions
//
  .table-bordered {
    box-shadow: map-get($table-elevation-shadow, shadow);
  }

  .table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-bg-accent;
    }
  }

//
// hover
//
  .table-hover {
    tbody tr {
      @include hover {
        background-color: $table-bg-hover;
      }
    }
  }

//
// table background
//
  @each $color in $palettes {
    $i: index($palettes, $color);

    .table .table-#{$color} {
      &,
      > td,
      > th {
        background-color: nth($palettes-color, $i);
        color: nth($palettes-text-color, $i);
      }
    }

    .table-hover .table-#{$color} {
      @include hover {
        &,
        > td,
        > th {
          background-color: nth($palettes-color-dark, $i);
          color: nth($palettes-text-color-dark, $i);
        }
      }
    }
  }

//
// table responsive
//
  .table-responsive {
    display: block;
    min-height: 0.01%;
    overflow-x: auto;
    width: 100%;
  }


//
// Prevent column shift for hidden row table headers
//
th.sr-only {
  display: none;
}