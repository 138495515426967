//
// misc
//
  .form-control-feedback,
  .form-text {
    display: block;
    margin-top: $textfield-margin-bottom;

    .form-control-lg + &,
    .form-control-static-lg + & {
      margin-top: $textfield-margin-bottom-lg;
    }

    .form-control-sm + &,,
    .form-control-static-sm + & {
      margin-top: $textfield-margin-bottom-sm;
    }
  }

  .form-control-file,
  .form-control-range {
    display: block;
  }

  .form-control-file {
    margin-top: $textfield-padding-top;
    margin-bottom: $textfield-margin-bottom;
  }
