.tooltip {
  display: block;
  font-size: $tooltip-font-size;
  opacity: 0;
  position: absolute;
  z-index: $tooltip-zindex;
  @include reset-text;

  &,
  &.fade,
  &.fade.show {
    transition-property: opacity;
  }

  &.bs-tether-element-attached-bottom,
  &.tooltip-top {
    margin-top: ($tooltip-margin * -1);
  }

  &.bs-tether-element-attached-left,
  &.tooltip-right {
    margin-left: $tooltip-margin;
  }

  &.bs-tether-element-attached-right,
  &.tooltip-left {
    margin-left: ($tooltip-margin * -1);
  }

  &.bs-tether-element-attached-top,
  &.tooltip-bottom {
    margin-top: $tooltip-margin;
  }
}

.tooltip-inner {
  background-color: $tooltip-bg;
  color: $tooltip-color;
  padding: $tooltip-padding-y $tooltip-padding-x;
  text-align: center;
  transform: scale(0.87);
  @include border-radius($border-radius);
  @include transition-acceleration(transform);
}

// show
  .tooltip.show {
    opacity: $tooltip-opacity;

    .tooltip-inner {
      transform: scale(1);
      @include transition-deceleration(transform);
    }
  }

// desktop
  @include media-breakpoint-up(md) {
    .tooltip {
      font-size: $tooltip-font-size-md-up;

      &.bs-tether-element-attached-bottom,
      &.tooltip-top {
        margin-top: ($tooltip-margin-md-up * -1);
      }

      &.bs-tether-element-attached-left,
      &.tooltip-right {
        margin-left: $tooltip-margin-md-up;
      }

      &.bs-tether-element-attached-right,
      &.tooltip-left {
        margin-left: ($tooltip-margin-md-up * -1);
      }

      &.bs-tether-element-attached-top,
      &.tooltip-bottom {
        margin-top: $tooltip-margin-md-up;
      }
    }

    .tooltip-inner {
      padding: $tooltip-padding-y-md-up $tooltip-padding-x-md-up;
    }
  }
