.pagination {
  background-color: $pagination-bg;
  display: flex;
  list-style: none;
  padding: $pagination-padding-y $pagination-padding-x;
}

.page-link {
  color: $btn-color;
  cursor: pointer;
  display: block;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: 1;
  margin-left: 1px;
  padding: $btn-padding-y $btn-padding-x;
  position: relative;
  text-align: center;
  white-space: nowrap;
  @include border-radius($border-radius);
  @include transition-standard(background-color, color);

  // active, focus, hover
    @include active-focus-hover {
      background-color: $btn-bg-active;
      color: $btn-color;
      text-decoration: none;
    }

  .page-item:first-child & {
    margin-left: 0;
  }

  .page-item.active & {
    background-color: $btn-bg-active;
  }

  .page-item.disabled & {
    background-color: transparent;
    color: $btn-color-disabled;
    cursor: $cursor-disabled;
  }
}

// size
  .pagination-lg .page-link {
    font-size: $btn-font-size-lg;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
  }

  .pagination-sm .page-link {
    font-size: $btn-font-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
