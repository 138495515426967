@mixin make-grid-columns() {
  %grid-column {
    min-height: 1px;
    position: relative;
    width: 100%;

    @include make-gutters;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    .col#{$infix} {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint) {
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
      }

      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @include make-col($i);
        }
      }

      @each $modifier in (pull, push) {
        @for $i from 0 through $grid-columns {
          .#{$modifier}#{$infix}-#{$i} {
            @include make-col-modifier($i, $modifier);
          }
        }
      }

      @for $i from 0 through ($grid-columns - 1) {
        @if not ($infix == "" and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-modifier($i, offset);
          }
        }
      }
    }
  }
}
