//
// the movement of an element is based on https://material.google.com/motion/movement.html
//

// based on https://material.google.com/motion/duration-easing.html#duration-easing-common-durations
  $transition-duration:                     0.3s !default;
  $transition-duration-lg:                  0.375s !default;
  $transition-duration-sm:                  0.225s !default;

  $transition-duration-desktop:             0.2s !default;
  $transition-duration-desktop-lg:          0.25s !default;
  $transition-duration-desktop-sm:          0.15s !default;

  $transition-duration-tablet:              0.39s !default;
  $transition-duration-tablet-lg:           0.4875s !default;
  $transition-duration-tablet-sm:           0.2925s !default;

// based on https://material.google.com/motion/duration-easing.html#duration-easing-natural-easing-curves
  $transition-timing-function-acceleration: cubic-bezier(0.4, 0.0, 1, 1) !default;
  $transition-timing-function-deceleration: cubic-bezier(0.0, 0.0, 0.2, 1) !default;
  $transition-timing-function-sharp:        cubic-bezier(0.4, 0.0, 0.6, 1) !default;
  $transition-timing-function-standard:     cubic-bezier(0.4, 0.0, 0.2, 1) !default;
