//
// todo: landscape picker
//

.picker {
  position: absolute;
  z-index: map-get($picker-elevation-shadow, elevation);
  user-select: none;
}

.picker__box {
  background-color: $picker-content-bg;
  overflow: hidden;
  @include border-bottom-radius($border-radius);
}

.picker__frame {
  background-color: $picker-holder-bg;
  opacity: 0;
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  @include transition-acceleration(opacity);

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 1px;
  }

  .picker--opened & {
    opacity: 1;
    @include transition-deceleration(opacity);
  }
}

.picker__holder {
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  transform: translate3d(0, 100%, 0);
  transition-delay: $transition-duration-sm;
  transition-duration: 0;
  transition-property: transform;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(sm) {
    transition-delay: $transition-duration-tablet-sm;
  }

  @include media-breakpoint-up(lg) {
    transition-delay: $transition-duration-desktop-sm;
  }

  .picker--opened & {
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translate3d(0, 0, 0);
    transition: none;

    &::before {
      opacity: 1;
    }
  }
}

.picker__input {
  &.form-control[readonly] {
    border-bottom-style: solid;
    color: inherit;
    cursor: text;
  }

  &.picker__input--active {
    border-bottom-color: $textfield-border-color-focus;
  }
}

.picker__wrap {
  box-shadow: map-get($picker-elevation-shadow, shadow);
  display: inline-block;
  margin: $picker-margin-y $picker-margin-x;
  max-width: ($picker-cell-size * 7 + $picker-inner-spacer-x * 2);
  outline: 0;
  position: relative;
  transform: scale(0.87);
  vertical-align: middle;
  @include border-radius($border-radius);
  @include transition-acceleration(transform);

  .picker--opened & {
    transform: scale(1);
    @include transition-deceleration(transform);
  }
}

//
// footer
//
  .picker__footer {
    padding: $picker-inner-spacer-y $picker-inner-spacer-x;
    text-align: right;

    button {
      margin-left: $picker-inner-spacer-x;
      min-width: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

//
// header
//
  .picker__header {
    height: $picker-cell-size;
    line-height: $picker-cell-size;
    margin-right: $picker-inner-spacer-x;
    margin-left: $picker-inner-spacer-x;
    position: relative;
    vertical-align: middle;
  }

  .picker__month,
  .picker__year {
    display: inline;
    margin-left: $picker-inner-spacer-x;

    &:first-child {
      margin-left: 0;
    }
  }

  .picker__nav--next,
  .picker__nav--prev {
    cursor: pointer;
    height: $picker-cell-size;
    margin-top: ($picker-cell-size / -2);
    position: absolute;
      top: 50%;
    width: $picker-cell-size;

    &,
    &.material-icons {
      line-height: $picker-cell-size;
    }
  }

  .picker__nav--next {
    // position
      right: 0;

    &:before {
      content: $picker-nav-next-icon;
    }
  }

  .picker__nav--prev {
    // position
      left: 0;

    &:before {
      content: $picker-nav-prev-icon;
    }
  }

//
// material date display
//
  .picker__date-display {
    background-color: $picker-header-bg;
    color: $picker-header-color;
    padding: $picker-header-padding;
    text-align: left;
    @include border-top-radius($border-radius);
  }

  .picker__date-display-bottom {
    @include text-truncate;
    @include typography-display-1;
  }

  .picker__day-display {
    margin-right: $picker-inner-spacer-x;
  }

  .picker__weekday-display {
    margin-right: $picker-inner-spacer-x;

    &:after {
      content: ",";
    }
  }

//
// table
//
  .picker__day {
    border-radius: 50%;
    cursor: pointer;
    height: $picker-cell-size;
    line-height: $picker-cell-size;
    margin: auto;
    vertical-align: middle;
    width: $picker-cell-size;

    &.picker__day--selected {
      background-color: $picker-day-bg-selected;
      color: $picker-day-color-selected;
    }
  }

  .picker__day--disabled {
    color: $picker-day-color-disabled;
    cursor: $cursor-disabled;
  }

  .picker__day--outfocus {
    display: none;
  }

  .picker__day--today {
    color: $picker-day-color-today;
    font-weight: bolder;
  }

  .picker__table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 $picker-inner-spacer-x;
    table-layout: fixed;

    td,
    th {
      border: 0;
      font-weight: normal;
      padding: 0;
      text-align: center;
      vertical-align: middle;
    }
  }

  .picker__weekday {
    color: $picker-weekday-color;
    height: $picker-cell-size;
    vertical-align: middle;
    width: $picker-cell-size;
  }
