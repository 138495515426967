//
// blockquote
//
  .blockquote {
    border-left: $blockquote-border-width solid $blockquote-border-color;
    margin-bottom: $spacer-md-y;
    padding: 0 $spacer-md-x;
    @include typography-title;
  }

  .blockquote-footer {
    color: $blockquote-small-color;
    display: block;
    margin-top: $spacer-xs-y;
    @include typography-caption;

    &::before {
      content: "\2014 \00A0";
    }
  }

  .blockquote-reverse {
    border-left: 0;
    border-right: $blockquote-border-width solid $blockquote-border-color;
    text-align: right;
  }

  .blockquote-reverse .blockquote-footer {
    &::after {
      content: "\00A0 \2014";
    }
    &::before {
      content: "";
    }
  }

//
// emphasis
//
  mark,
  .mark {
    background-color: $mark-bg;
    padding: $mark-padding;
  }

  small,
  .small {
    font-size: $small-font-size;
    font-weight: normal;
  }

//
// headings
//
  .typography-display-4 {
    @include typography-display-4;
  }

  .typography-display-3 {
    @include typography-display-3;
  }

  .typography-display-2 {
    @include typography-display-2;
  }

  .typography-display-1 {
    @include typography-display-1;
  }

  .typography-headline {
    @include typography-headline;
  }

  .typography-title {
    @include typography-title;
  }

  .typography-heading-xs {
    @include typography-heading-xs;
  }

  .typography-heading-xxs {
    @include typography-heading-xxs;
  }

  .typography-subheading {
    @include typography-subheading;
  }

  .typography-body-2 {
    @include typography-body-2;
  }

  .typography-body-1 {
    @include typography-body-1;
  }

  .typography-caption {
    @include typography-caption;
  }

  // bootstrap classes
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: $headings-color;
      margin-bottom: $headings-margin-bottom;
    }

    h1,
    h2,
    h3,
    .h1,
    .h2,
    .h3{
      font-family: $headings-font-family;
    }

    h1,
    .h1 {
      @extend .typography-display-2;
    }

    h2,
    .h2 {
      @extend .typography-display-1;
    }

    h3,
    .h3 {
      @extend .typography-headline;
    }

    h4,
    .h4 {
      @extend .typography-title;
    }

    h5,
    .h5 {
      @extend .typography-heading-xs;
    }

    h6,
    .h6 {
      @extend .typography-heading-xxs;
    }

    .display-1 {
      @extend .typography-display-4;
    }

    .display-2 {
      @extend .typography-display-3;
    }

    .display-3 {
      @extend .typography-display-2;
    }

    .display-4 {
      @extend .typography-display-1;
    }

    .lead {
      @extend .typography-title;
    }

//
// hr
//
  hr {
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    margin-top: $spacer-md-y;
    margin-bottom: $spacer-md-y;
  }

//
// list
//
  // Setting a default .list class
  .list {
    li {
      margin-bottom: .25rem;
    }
  }

  .list-inline {
    @include list-unstyled;
  }

  .list-inline-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $list-inline-item-spacer;
    }
  }

  .list-unstyled {
    @include list-unstyled;
  }

  // Align the bullets to the heading above
  .list-aligned {
    padding-left: 1rem;
  }

  // Give technically nested lists (as opposed to visually) the root list style
  .list-rollback {
    list-style-type: disc;
  }

//
// misc
//
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
