[class*="btn-flat"],
[class*="btn-outline"] {
  background-color: transparent;
  box-shadow: none;

  // active, focus, hover
    &:active,
    &.active {
      background-color: $btn-bg-active;
      box-shadow: none;
    }

  // disabled
    &:disabled,
    &.disabled {
      background-color: transparent;
    }
}

// colour
  .btn-flat-inverse,
  .btn-outline-inverse {
    @include plain-active-focus-hover {
      color: $btn-color-inverse;
    }

    &::before {
      background-color: $btn-flat-focus-overlay-inverse;
    }

    // active, focus, hover
      &:active,
      &.active {
        background-color: $btn-bg-inverse-active;
      }

    // disabled
      &:disabled,
      &.disabled {
        color: $btn-color-inverse-disabled;
      }
  }

  @each $color in $palettes {
    $i: index($palettes, $color);

    .btn-flat-#{$color},
    .btn-outline-#{$color} {
      @include plain-active-focus-hover {
        color: nth($palettes-color, $i);
      }
    }
  }
