// alignment
  .text-justify {
    text-align: justify !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .whitespace-normal {
    white-space: normal;
  }

  .text-truncate {
    @include text-truncate;
  }

// horizontal
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint);

        .text#{$infix}-center {
          text-align: center !important;
        }

        .text#{$infix}-left {
          text-align: left !important;
        }

        .text#{$infix}-right {
          text-align: right !important;
        }
      }
    }

// color
  // black and white
    @include text-emphasis-variant('.text-black', $black);
    @include text-emphasis-variant('.text-black-primary', $black-primary);
    @include text-emphasis-variant('.text-black-secondary', $black-secondary);
    @include text-emphasis-variant('.text-black-hint', $black-hint);
    @include text-emphasis-variant('.text-black-divider', $black-divider);

    @include text-emphasis-variant('.text-white', $white);
    @include text-emphasis-variant('.text-white-primary', $white-primary);
    @include text-emphasis-variant('.text-white-secondary', $white-secondary);
    @include text-emphasis-variant('.text-white-hint', $white-hint);
    @include text-emphasis-variant('.text-white-divider', $white-divider);

  // font colour
    @include text-emphasis-variant('.text-gray-dark', $black-primary, $black);

  // offwhite
    @include text-emphasis-variant('.text-muted', $text-muted, $text-muted-active);

  // palette
    @each $color in $palettes {
      $i: index($palettes, $color);

      @include text-emphasis-variant('.text-#{$color}', nth($palettes-color, $i), nth($palettes-color-dark, $i));
    }

    @include text-emphasis-variant('.text-inverse', $white-primary, $white);

// italcs and weight
  .font-italic {
    font-style: italic;
  }

  .font-weight-bold {
    font-weight: $font-weight-bold !important;
  }

  .font-weight-medium {
    font-weight: $font-weight-medium;
  }

  .font-weight-light {
    font-weight: $font-weight-light;
  }

  .font-weight-normal,
  .font-weight-regular {
    font-weight: $font-weight-regular;
  }

// misc
  .text-hide {
    @include text-hide;
  }

// transformation
  .text-capitalize {
    text-transform: capitalize !important;
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

// size
  .font-xxl  { font-size: $font-size-xxl  !important; }   // 24px
  .font-xl   { font-size: $font-size-xl   !important; }   // 22px
  .font-l    { font-size: $font-size-l    !important; }   // 20px
  .font-m    { font-size: $font-size-m    !important; }   // 18px
  .font-s    { font-size: $font-size-s    !important; }   // 16px
  .font-xs   { font-size: $font-size-xs   !important; }   // 14px
  .font-xxs  { font-size: $font-size-xxs  !important; }   // 12px

  .font-sm {
    font-size: 0.9rem !important;
  }

// font family
  .font-family-base {
    font-family: $font-family-base !important;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
