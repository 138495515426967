@mixin reset-text {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: $line-height-base;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
}

@mixin reset-to-material-icons($base: $font-size-old-base) {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons";
  font-feature-settings: "liga";
  font-size: #{($material-icon-size / $font-size-root) / strip-unit($base)}em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: #{strip-unit($base) / (($material-icon-size / $font-size-root) / strip-unit($base))}em;;
  text-rendering: optimizeLegibility;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
