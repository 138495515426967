//
// positioning
//
  .fixed-bottom {
    position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
    z-index: map-get($toolbar-elevation-shadow, elevation);
  }

  .fixed-top {
    position: fixed;
      top: 0;
      right: 0;
      left: 0;
    z-index: map-get($toolbar-elevation-shadow, elevation);
  }

  .sticky-top {
    position: sticky;
      top: 0;
    z-index: map-get($toolbar-elevation-shadow, elevation);
  }

  .position-static {
    position: static !important;
  }
.position-relative {
  position: relative;
}
