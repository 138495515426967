//
// border
//
  $border-color:         $black-divider !default;
  $border-color-inverse: $white-divider !default;
  $border-color-solid:   $black-divider-solid !default;
  $border-radius:        2px !default;
  $border-width:         1px !default;

//
// size
//
  $sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
  ) !default;

//
// spacer
//
  $spacer:               1rem !default;
  $spacer-x:             $spacer !default;
  $spacer-y:             $spacer !default;

  $spacer-xs:            ($spacer / 4) !default;
  $spacer-xs-x:          ($spacer-x / 4) !default;
  $spacer-xs-y:          ($spacer-y / 4) !default;
  $spacer-sm:            ($spacer / 2) !default;
  $spacer-sm-x:          ($spacer-x / 2) !default;
  $spacer-sm-y:          ($spacer-y / 2) !default;
  $spacer-md:            $spacer !default;
  $spacer-md-x:          $spacer-x !default;
  $spacer-md-y:          $spacer-y !default;
  $spacer-lg:            ($spacer * 1.5) !default;
  $spacer-lg-x:          ($spacer-x * 1.5) !default;
  $spacer-lg-y:          ($spacer-y * 1.5) !default;
  $spacer-xl:            ($spacer * 3) !default;
  $spacer-xl-x:          ($spacer-x * 3) !default;
  $spacer-xl-y:          ($spacer-y * 3) !default;

  $spacers: (
    0: (
      x: 0,
      y: 0
    ),
    no: (
      x: 0,
      y: 0
    ),
    1: (
      x: $spacer-xs-x,
      y: $spacer-xs-y
    ),
    xs: (
      x: $spacer-xs-x,
      y: $spacer-xs-y
    ),
    2: (
      x: $spacer-sm-x,
      y: $spacer-sm-y
    ),
    sm: (
      x: $spacer-sm-x,
      y: $spacer-sm-y
    ),
    3: (
      x: $spacer-x,
      y: $spacer-y
    ),
    md: (
      x: $spacer-x,
      y: $spacer-y
    ),
    4: (
      x: $spacer-lg-x,
      y: $spacer-lg-y
    ),
    lg: (
      x: $spacer-lg-x,
      y: $spacer-lg-y
    ),
    5: (
      x: $spacer-xl-x,
      y: $spacer-xl-y
    ),
    xl: (
      x: $spacer-xl-x,
      y: $spacer-xl-y
    )
  ) !default;
