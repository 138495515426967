//
// basic colour
// based on https://material.google.com/style/color.html#color-color-palette
//
  

  //BMJ Blue
  $palette-blue-30:               #d4e2f1 !default;
  $palette-blue-040:              #ecf0f5 !default;
  $palette-blue-050:              #e5eef7 !default;
  $palette-blue-100:              #bfd4eb !default;
  $palette-blue-200:              #95b7dd !default;
  $palette-blue-300:              #6a9acf !default;
  $palette-blue-400:              #4a84c5 !default;
  $palette-blue-500:              #2a6ebb !default;
  $palette-blue-600:              #2566b5 !default;
  $palette-blue-700:              #1f5bac !default;
  $palette-blue-800:              #1951a4 !default;
  $palette-blue-900:              #0f3f96 !default;
  $palette-blue-a100:             #c6d8ff !default;
  $palette-blue-a200:             #93b5ff !default;
  $palette-blue-a400:             #6092ff !default;
  $palette-blue-a700:             #4780ff !default;
  
  $palette-blue-grey-050:         #eceff1 !default;
  $palette-blue-grey-100:         #cfd8dc !default;
  $palette-blue-grey-200:         #b0bec5 !default;
  $palette-blue-grey-300:         #90a4ae !default;
  $palette-blue-grey-400:         #78909c !default;
  $palette-blue-grey-500:         #607d8b !default;
  $palette-blue-grey-600:         #546e7a !default;
  $palette-blue-grey-700:         #455a64 !default;
  $palette-blue-grey-800:         #37474f !default;
  $palette-blue-grey-900:         #263238 !default;

  $palette-brown-050:             #efebe9 !default;
  $palette-brown-100:             #d7ccc8 !default;
  $palette-brown-200:             #bcaaa4 !default;
  $palette-brown-300:             #a1887f !default;
  $palette-brown-400:             #8d6e63 !default;
  $palette-brown-500:             #795548 !default;
  $palette-brown-600:             #6d4c41 !default;
  $palette-brown-700:             #5d4037 !default;
  $palette-brown-800:             #4e342e !default;
  $palette-brown-900:             #3e2723 !default;
  // BMJ Aqua (Teal!)
  $palette-teal-050:              #e0f6f5 !default;
  $palette-teal-100:              #b3e8e5 !default;
  $palette-teal-200:              #80d9d4 !default;
  $palette-teal-300:              #4dc9c3 !default;
  $palette-teal-400:              #26beb6 !default;
  $palette-teal-500:              #00b2a9 !default;
  $palette-teal-600:              #00aba2 !default;
  $palette-teal-700:              #00a298 !default;
  $palette-teal-800:              #00998f !default;
  $palette-teal-900:              #008a7e !default;
  $palette-teal-a100:             #b6fff8 !default;
  $palette-teal-a200:             #83fff3 !default;
  $palette-teal-a400:             #50ffee !default;
  $palette-teal-a700:             #36ffec !default;
  //BMJ Orange
  $palette-orange-050:          #fceee4 !default;
  $palette-orange-100:          #f7d5bd !default;
  $palette-orange-200:          #f1b991 !default;
  $palette-orange-300:          #eb9c64 !default;
  $palette-orange-400:        #e78743 !default;
  $palette-orange-500:        #e37222 !default;
  $palette-orange-600:        #e06a1e !default;
  $palette-orange-700:        #dc5f19 !default;
  $palette-orange-800:        #d85514 !default;
  $palette-orange-900:        #d0420c !default;
  $palette-orange-a100:       #fffcfc !default;
  $palette-orange-a200:       #ffd5c9 !default;
  $palette-orange-a400:       #ffae96 !default;
  $palette-orange-a700:       #ff9a7c !default;
  //BMJ Purple
  $palette-deep-purple-050:       #efebf8 !default;
  $palette-deep-purple-100:       #d8ceee !default;
  $palette-deep-purple-200:       #beaee3 !default;
  $palette-deep-purple-300:       #a48dd7 !default;
  $palette-deep-purple-400:       #9174cf !default;
  $palette-deep-purple-500:       #7d5cc6 !default;
  $palette-deep-purple-600:       #7554c0 !default;
  $palette-deep-purple-700:       #6a4ab9 !default;
  $palette-deep-purple-800:       #6041b1 !default;
  $palette-deep-purple-900:       #4d30a4 !default;
  $palette-deep-purple-a100:      #efebff !default;
  $palette-deep-purple-a200:      #c8b8ff !default;
  $palette-deep-purple-a400:      #a185ff !default;
  $palette-deep-purple-a700:      #8e6bff !default;

  $palette-green-050:             #e8f5e9 !default;
  $palette-green-100:             #c8e6c9 !default;
  $palette-green-200:             #a5d6a7 !default;
  $palette-green-300:             #81c784 !default;
  $palette-green-400:             #66bb6a !default;
  $palette-green-500:             #4caf50 !default;
  $palette-green-600:             #43a047 !default;
  $palette-green-700:             #388e3c !default;
  $palette-green-800:             #2e7d32 !default;
  $palette-green-900:             #1b5e20 !default;
  $palette-green-a100:            #b9f6ca !default;
  $palette-green-a200:            #69f0ae !default;
  $palette-green-a400:            #00e676 !default;
  $palette-green-a700:            #00c853 !default;

  $palette-grey-050:              #fafafa !default;
  $palette-grey-050:              #fafafa !default;
  $palette-grey-100:              #f5f5f5 !default;
  $palette-grey-200:              #eeeeee !default;
  $palette-grey-300:              #e0e0e0 !default;
  $palette-grey-400:              #bdbdbd !default;
  $palette-grey-a400:             #f2f2f2 !default;
  $palette-grey-500:              #9e9e9e !default;
  $palette-grey-a500:             #747678 !default;  
  $palette-grey-600:              #757575 !default;
  $palette-grey-a600:             #5F6163 !default;  
  $palette-grey-700:              #616161 !default;
  $palette-grey-800:              #424242 !default;
  $palette-grey-900:              #212121 !default;
  $palette-grey-light:            #E5E6E6 !default;

  /* BMJ Grey 100 */
  $palette-bmj-grey-100:          #cccdcd !default;

  $palette-indigo-050:            #e8eaf6 !default;
  $palette-indigo-100:            #c5cae9 !default;
  $palette-indigo-200:            #9fa8da !default;
  $palette-indigo-300:            #7986cb !default;
  $palette-indigo-400:            #5c6bc0 !default;
  $palette-indigo-500:            #3f51b5 !default;
  $palette-indigo-600:            #3949ab !default;
  $palette-indigo-700:            #303f9f !default;
  $palette-indigo-800:            #283593 !default;
  $palette-indigo-900:            #1a237e !default;
  $palette-indigo-a100:           #8c9eff !default;
  $palette-indigo-a200:           #536dfe !default;
  $palette-indigo-a400:           #3d5afe !default;
  $palette-indigo-a700:           #304ffe !default;

  $palette-light-blue-050:        #e1f5fe !default;
  $palette-light-blue-100:        #b3e5fc !default;
  $palette-light-blue-200:        #81d4fa !default;
  $palette-light-blue-300:        #4fc3f7 !default;
  $palette-light-blue-400:        #29b6f6 !default;
  $palette-light-blue-500:        #03a9f4 !default;
  $palette-light-blue-600:        #039be5 !default;
  $palette-light-blue-700:        #0288d1 !default;
  $palette-light-blue-800:        #0277bd !default;
  $palette-light-blue-900:        #01579b !default;
  $palette-light-blue-a100:       #80d8ff !default;
  $palette-light-blue-a200:       #40c4ff !default;
  $palette-light-blue-a400:       #00b0ff !default;
  $palette-light-blue-a700:       #0091ea !default;
  $palette-blue-a900:             #1F5189 !default;

  $palette-light-green-050:       #f1f8e9 !default;
  $palette-light-green-100:       #dcedc8 !default;
  $palette-light-green-200:       #c5e1a5 !default;
  $palette-light-green-300:       #aed581 !default;
  $palette-light-green-400:       #9ccc65 !default;
  $palette-light-green-500:       #8bc34a !default;
  $palette-light-green-600:       #7cb342 !default;
  $palette-light-green-700:       #689f38 !default;
  $palette-light-green-800:       #558b2f !default;
  $palette-light-green-900:       #33691e !default;
  $palette-light-green-a100:      #ccff90 !default;
  $palette-light-green-a200:      #b2ff59 !default;
  $palette-light-green-a400:      #76ff03 !default;
  $palette-light-green-a700:      #64dd17 !default;

  $palette-lime-050:              #f9fbe7 !default;
  $palette-lime-100:              #f0f4c3 !default;
  $palette-lime-200:              #e6ee9c !default;
  $palette-lime-300:              #dce775 !default;
  $palette-lime-400:              #d4e157 !default;
  $palette-lime-500:              #cddc39 !default;
  $palette-lime-600:              #c0ca33 !default;
  $palette-lime-700:              #afb42b !default;
  $palette-lime-800:              #9e9d24 !default;
  $palette-lime-900:              #827717 !default;
  $palette-lime-a100:             #f4ff81 !default;
  $palette-lime-a200:             #eeff41 !default;
  $palette-lime-a400:             #c6ff00 !default;
  $palette-lime-a700:             #aeea00 !default;

  //BMJ Pink
  $palette-pink-040:              #fbecf6 !default;
  $palette-pink-050:              #f8e0f0 !default;
  $palette-pink-100:              #eeb3da !default;
  $palette-pink-200:              #e280c2 !default;
  $palette-pink-300:              #d64da9 !default;
  $palette-pink-400:              #ce2696 !default;
  $palette-pink-500:              #c50084 !default;
  $palette-pink-600:              #bf007c !default;
  $palette-pink-700:              #b80071 !default;
  $palette-pink-800:              #b00067 !default;
  $palette-pink-900:              #a30054 !default;
  $palette-pink-a100:             #ffcee4 !default;
  $palette-pink-a200:             #ff9bc8 !default;
  $palette-pink-a400:             #ff68ab !default;
  $palette-pink-a700:             #ff4e9d !default;

  $palette-purple-050:            #f3e5f5 !default;
  $palette-purple-100:            #e1bee7 !default;
  $palette-purple-200:            #ce93d8 !default;
  $palette-purple-300:            #ba68c8 !default;
  $palette-purple-400:            #ab47bc !default;
  $palette-purple-500:            #9c27b0 !default;
  $palette-purple-600:            #8e24aa !default;
  $palette-purple-700:            #7b1fa2 !default;
  $palette-purple-800:            #6a1b9a !default;
  $palette-purple-900:            #4a148c !default;
  $palette-purple-a100:           #ea80fc !default;
  $palette-purple-a200:           #e040fb !default;
  $palette-purple-a400:           #d500f9 !default;
  $palette-purple-a700:           #aa00ff !default;

  $palette-red-050:               #f9e4e6 !default;
  $palette-red-100:               #f0bcc0 !default;
  $palette-red-200:               #e69096 !default;
  $palette-red-300:               #dc636b !default;
  $palette-red-400:               #d5414c !default;
  $palette-red-500:               #cd202c !default;
  $palette-red-600:               #c81c27 !default;
  $palette-red-700:               #c11821 !default;
  $palette-red-800:               #ba131b !default;
  $palette-red-900:               #ae0b10 !default;
  $palette-red-a100:              #ffdbdc !default;
  $palette-red-a200:              #ffa8aa !default;
  $palette-red-a400:              #ff7578 !default;
  $palette-red-a700:              #ff5c5f !default;

  $palette-yellow-050:            #fffde7 !default;
  $palette-yellow-100:            #fff9c4 !default;
  $palette-yellow-200:            #fff59d !default;
  $palette-yellow-300:            #fff176 !default;
  $palette-yellow-400:            #ffee58 !default;
  $palette-yellow-500:            #ffeb3b !default;
  $palette-yellow-600:            #fdd835 !default;
  $palette-yellow-700:            #fbc02d !default;
  $palette-yellow-800:            #f9a825 !default;
  $palette-yellow-900:            #f57f17 !default;
  $palette-yellow-a100:           #ffff8d !default;
  $palette-yellow-a200:           #ffff00 !default;
  $palette-yellow-a400:           #ffea00 !default;
  $palette-yellow-a700:           #ffd600 !default;

//
// black and white
// based on https://material.google.com/style/color.html#color-text-background-colors
//
  $black:                         #000000 !default;
  $black-primary:                 rgba(red($black), green($black), blue($black), 0.75) !default;
  $black-secondary:               rgba(red($black), green($black), blue($black), 0.54) !default;
  $black-hint:                    rgba(red($black), green($black), blue($black), 0.38) !default;
  $black-divider:                 rgba(red($black), green($black), blue($black), 0.12) !default;
  $black-primary-solid:           #212121 !default;
  $black-charcoal:                #333333 !default;
  $black-secondary-solid:         #757575 !default;
  $black-hint-solid:              #9e9e9e !default;
  $black-divider-solid:           #e1e1e1 !default;
  $black-disabled-solid:          #999999 !default;

  $white:                         #ffffff !default;
  $white-primary:                 rgba(red($white), green($white), blue($white), 1.00) !default;
  $white-secondary:               rgba(red($white), green($white), blue($white), 0.70) !default;
  $white-hint:                    rgba(red($white), green($white), blue($white), 0.50) !default;
  $white-divider:                 rgba(red($white), green($white), blue($white), 0.12) !default;
  $transparent: transparent;

//
// offwhite
// based on https://material.google.com/style/color.html#color-themes
//
  $offwhite:                      $palette-grey-100 !default;
  $offwhite-dark:                 $palette-grey-300 !default;
  $offwhite-light:                $palette-grey-050 !default;

//
// palette
// based on https://material.google.com/style/color.html#color-color-schemes
// change these values to customise colour palettes
//
  $brand-color:                   $palette-blue-500 !default;
  $brand-color-dark:              $palette-blue-700 !default;
  $brand-color-light:             $palette-blue-100 !default;
  $brand-text-color:              $white-primary !default;
  $brand-text-color-dark:         $white-primary !default;
  $brand-text-color-light:        $black-primary !default;

  $brand-color-accent:            $palette-pink-900 !default;
  $brand-color-accent-dark:       $palette-pink-a400 !default;
  $brand-color-accent-light:      $palette-pink-a100 !default;
  $brand-text-color-accent:       $white-primary !default;
  $brand-text-color-accent-dark:  $white-primary !default;
  $brand-text-color-accent-light: $black-primary !default;

  // change the following lists to add, edit, or remove colours used throughout material css
    $palettes: (
      "primary",
      // "primary" inherits brand colour, so that the naming is in line with Bootstrap 4
      "secondary",
      // "secondary" inherits brand accent colour, so that the naming is in line with Bootstrap 4
      "danger",
      "info",
      "success",
      "warning"
    ) !default;

    $palettes-color: (
      $brand-color,
      $brand-color-accent,
      $palette-red-500,
      $palette-blue-500,
      $palette-green-500,
      $palette-orange-500

    ) !default;

    $palettes-color-dark: (
      $brand-color-dark,
      $brand-color-accent-dark,
      $palette-red-700,
      $palette-blue-700,
      $palette-green-700,
      $palette-orange-700
    ) !default;

    $palettes-color-light: (
      $brand-color-light,
      $brand-color-accent-light,
      $palette-red-300,
      $palette-blue-300,
      $palette-green-300,
      $palette-orange-300
    ) !default;

    $palettes-text-color: (
      $brand-text-color,
      $brand-text-color-accent,
      $white-primary,
      $white-primary,
      $black-primary,
      $black-primary
    ) !default;

    $palettes-text-color-dark: (
      $brand-text-color-dark,
      $brand-text-color-accent-dark,
      $white-primary,
      $white-primary,
      $white-primary,
      $black-primary
    ) !default;

    $palettes-text-color-light: (
      $brand-text-color-light,
      $brand-text-color-accent-light,
      $black-primary,
      $black-primary,
      $black-primary,
      $black-primary
    ) !default;

$palette-alert: #ffe8ae;
