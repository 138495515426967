.invisible {
  visibility: hidden !important;
}

//
// print
//
  .hidden-print {
    @media print {
      display: none !important;
    }
  }

  .visible-print-block {
    display: none !important;
    @media print {
      display: block !important;
    }
  }

  .visible-print-inline {
    display: none !important;
    @media print {
      display: inline !important;
    }
  }

  .visible-print-inline-block {
    display: none !important;
    @media print {
      display: inline-block !important;
    }
  }

//
// responsive
//
  @each $breakpoint in map-keys($grid-breakpoints) {
    .hidden-#{$breakpoint}-up {
      @include media-breakpoint-up($breakpoint) {
        display: none !important;
      }
    }

    .hidden-#{$breakpoint}-down {
      @include media-breakpoint-down($breakpoint) {
        display: none !important;
      }
    }
  }
