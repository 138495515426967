code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

code {
  background-color: $code-bg;
  color: $code-color;
  font-size: $code-font-size;
  padding: $code-padding;
  @include border-radius($border-radius);
}

kbd {
  background-color: $kbd-bg;
  color: $kbd-color;
  font-size: $code-font-size;
  padding: $code-padding;
  @include border-radius($border-radius);

  kbd {
    font-size: 100%;
    padding: 0;
  }
}

pre {
  background-color: $pre-bg;
  color: $pre-color;
  display: block;
  font-size: $code-font-size;
  margin-top: 0;
  margin-bottom: $pre-margin-bottom;
  @include border-radius($border-radius);

  code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
  }
}

.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}
