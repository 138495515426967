//
// appbar / toolbar (navbar)
// based on https://material.google.com/components/toolbars.html#toolbars-usage
//
  $toolbar-color-dark:          $black-primary !default;
  $toolbar-color-light:         $white-primary !default;
  $toolbar-element-bg-dark:     $black-divider !default;
  $toolbar-element-bg-light:    $white-divider !default;
  $toolbar-elevation-shadow:    $elevation-shadow-4dp !default;
  $toolbar-height:              3.5rem !default;
  $toolbar-link-font-size:      1rem !default;
  $toolbar-link-height:         2.25rem !default;
  $toolbar-link-opacity:        0.7 !default;
  $toolbar-link-opacity-active: 1 !default;
  $toolbar-link-padding-x:      $spacer-md-x !default;
  $toolbar-link-padding-y:      (($toolbar-link-height - $toolbar-link-font-size) / 2) !default;
  $toolbar-padding-x:           $spacer-md-x !default;
  $toolbar-padding-y:           (($toolbar-height - $toolbar-link-height) / 2) !default;
  $toolbar-toggler-bg-dark:     url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.87' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'/%3E%3C/svg%3E") !default;
  $toolbar-toggler-bg-light:    url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23ffffff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'/%3E%3C/svg%3E") !default;
  $toolbar-waterfall-bg:        $brand-color !default;

//
// button
// based on https://material.google.com/components/buttons.html#buttons-style
// n.b. material design guideline only specifies specs for default button (.btn) and dense button (.btn-sm)
//
  $btn-bg:                      transparent !default;
  $btn-bg-active:               rgba(153, 153, 153, 0.40) !default;
  $btn-bg-disabled:             $black-divider !default;
  $btn-block-spacer:            $spacer-xs-y !default;
  $btn-color:                   $black-primary !default;
  $btn-color-disabled:          rgba(0, 0, 0, 0.26) !default;
  $btn-elevation-shadow:        $elevation-shadow-2dp !default;
  $btn-elevation-shadow-active: $elevation-shadow-8dp !default;
  $btn-focus-overlay:           $black-divider !default;
  $btn-font-weight:             $font-weight-medium !default;
  $btn-min-width:               5.5rem !default;

  // inverse
  $btn-bg-inverse-disabled:     $white-divider !default;
  $btn-bg-inverse-active:       rgba(204, 204, 204, 0.25) !default;
  $btn-color-inverse:           $white-primary !default;
  $btn-color-inverse-disabled:  rgba(255, 255, 255, 0.30) !default;

  // size
  $btn-font-size:               0.875rem !default;
  $btn-font-size-lg:            0.9375rem !default;
  $btn-font-size-sm:            0.8125rem !default;
  $btn-height:                  2.25rem !default;
  $btn-height-lg:               2.5rem !default;
  $btn-height-sm:               2rem !default;
  $btn-padding-x:               $spacer-md-x !default;
  $btn-padding-x-lg:            $btn-padding-x !default;
  $btn-padding-x-sm:            $btn-padding-x !default;
  $btn-padding-y:               (($btn-height - $btn-font-size) / 2) !default;
  $btn-padding-y-lg:            (($btn-height-lg - $btn-font-size-lg) / 2) !default;
  $btn-padding-y-sm:            (($btn-height-sm - $btn-font-size-sm) / 2) !default;

//
// button: flat button
// based on https://material.google.com/components/buttons.html#buttons-flat-buttons
//
  $btn-flat-bg-opacity:            0.4 !default;
  $btn-flat-focus-overlay-inverse: rgba(204, 204, 204, 0.12) !default;
  $btn-flat-focus-overlay-opacity: 0.12 !default;

//
// button: floating action button
// based on https://material.google.com/components/buttons-floating-action-button.html#buttons-floating-action-button-floating-action-button
//
  $btn-float-elevation-shadow:        $elevation-shadow-6dp !default;
  $btn-float-elevation-shadow-active: $elevation-shadow-12dp !default;
  $btn-float-font-size:               ($material-icon-size / $font-size-root * 1rem) !default;

  // size
  $btn-float-size:                    3.5rem !default;
  $btn-float-size-sm:                 2.5rem !default;

//
// card
// based on https://material.google.com/components/cards.html#cards-usage
//
  $card-action-spacer-x:         $spacer-sm-x !default;
  $card-action-spacer-y:         $spacer-sm-y !default;
  $card-bg:                      $white-primary !default;
  $card-bg-inverse:              $palette-grey-800 !default;
  $card-border-color:            $border-color !default;
  $card-border-width:            $border-width !default;
  $card-color:                   $black-primary !default;
  $card-color-inverse:           $white-primary !default;
  $card-elevation-shadow:        $elevation-shadow-2dp !default;
  $card-elevation-shadow-active: $elevation-shadow-8dp !default;
  $card-margin-bottom:           $spacer-md-y !default;
  $card-spacer-inner-x:          $spacer-md-x !default;
  $card-spacer-inner-y:          $spacer-md-y !default;
  $card-spacer-outer-x:          $spacer-sm-x !default;
  $card-spacer-outer-y:          $spacer-sm-y !default;

//
// chip (label)
// based on https://material.google.com/components/chips.html#chips-specs
//
  $chip-active-overlay:          $black-divider !default;
  $chip-bg:                      $black-divider !default;
  $chip-bg-inverse:              $white-divider !default;
  $chip-color:                   $black-primary !default;
  $chip-color-inverse:           $white-primary !default;
  $chip-elevation-shadow-active: $elevation-shadow-2dp !default;
  $chip-font-size:               0.8125rem !default;
  $chip-font-weight:             $font-weight-regular !default;
  $chip-height:                  2rem !default;
  $chip-padding-x:               0.75rem !default;
  $chip-padding-y:               (($chip-height - $chip-font-size) / 2) !default;

//
// colour theme
// based on https://material.google.com/style/color.html#color-themes
//
  $dark-theme-1:  $black !default;
  $dark-theme-2:  $palette-grey-900 !default;
  $dark-theme-3:  #303030 !default;
  $dark-theme-4:  $palette-grey-800 !default;

  $light-theme-1: $palette-grey-300 !default;
  $light-theme-2: $palette-grey-100 !default;
  $light-theme-3: $palette-grey-050 !default;
  $light-theme-4: $white !default;
  $transparent: $transparent !default;
  $dark-blue:    $palette-blue-a900 !default;
  $light-grey:   $palette-grey-light !default;

//
// data table (table)
// based on https://material.google.com/components/data-tables.html#data-tables-structure
//
  $table-bg:                 $white-primary !default;
  $table-bg-accent:          $palette-grey-100 !default;
  $table-bg-hover:           $palette-grey-200 !default;
  $table-border-color:       $border-color-solid !default;
  $table-border-width:       $border-width !default;
  $table-cell-padding-x:     1.75rem !default;
  $table-cell-padding-x-alt: $spacer-lg-x !default;
  $table-elevation-shadow:   $elevation-shadow-2dp !default;
  $table-margin-bottom:      $spacer-md-y !default;

  $table-tbody-cell-height:  3rem !default;
  $table-tbody-color:        $black-primary !default;
  $table-tbody-font-size:    0.8125rem !default;
  $table-tbody-font-weight:  $font-weight-regular !default;
  $table-tbody-padding-y:    (($table-tbody-cell-height - $table-tbody-font-size * $line-height-base) / 2) !default;

  $table-tfoot-cell-height:  3.5rem !default;
  $table-tfoot-color:        $black-secondary !default;
  $table-tfoot-font-size:    0.75rem !default;
  $table-tfoot-font-weight:  $font-weight-regular !default;
  $table-tfoot-padding-y:    (($table-tfoot-cell-height - $table-tfoot-font-size * $line-height-base) / 2) !default;

  $table-thead-cell-height:  $table-tfoot-cell-height !default;
  $table-thead-color:        $table-tfoot-color !default;
  $table-thead-font-size:    $table-tfoot-font-size !default;
  $table-thead-font-weight:  $font-weight-medium !default;
  $table-thead-padding-y:    (($table-thead-cell-height - $table-thead-font-size * $line-height-base) / 2) !default;

//
// dialog (modal)
// based on https://material.google.com/components/dialogs.html#dialogs-specs
//
  $dialog-backdrop-bg:          $black-hint !default;
  $dialog-content-bg:           $white-primary !default;
  $dialog-elevation-shadow:     $elevation-shadow-24dp !default;
  $dialog-footer-btn-min-width: 4rem !default;
  $dialog-footer-spacer-x:      $spacer-sm-x !default;
  $dialog-footer-spacer-y:      $spacer-sm-y !default;
  $dialog-inner-padding:        1.25rem $spacer-lg-x !default;
  $dialog-margin-x:             $spacer-lg-x !default;
  $dialog-margin-y:             $spacer-lg-y !default;
  $dialog-width:                35rem !default;
  $dialog-width-lg:             52.5rem !default;
  $dialog-width-sm:             17.5rem !default;

//
// expansion panel (list group)
// based on https://material.google.com/components/expansion-panels.html#expansion-panels-specs
//
  $expansion-panel-bg:                  $white-primary !default;
  $expansion-panel-bg-active:           $palette-grey-200 !default;
  $expansion-panel-bg-disabled:         $expansion-panel-bg !default;
  $expansion-panel-border-color:        $border-color !default;
  $expansion-panel-border-width:        $border-width !default;
  $expansion-panel-color:               $black-primary !default;
  $expansion-panel-color-active:        $expansion-panel-color !default;
  $expansion-panel-color-disabled:      $black-hint !default;
  $expansion-panel-elevation-shadow:    $elevation-shadow-2dp !default;
  $expansion-panel-font-size:           0.9375rem !default;
  $expansion-panel-font-size-secondary: 0.75rem !default;
  $expansion-panel-height:              3rem !default;
  $expansion-panel-inner-spacer-x:      $spacer-lg-x !default;
  $expansion-panel-inner-spacer-y:      ($expansion-panel-inner-spacer-x / 2) !default;

//
// menu (dropdown)
// based on https://material.google.com/components/menus.html#menus-specs
//
  $menu-bg:                     $white-primary !default;
  $menu-divider-bg:             $black-divider !default;
  $menu-font-size:              1rem !default;
  $menu-font-size-cascading:    0.9375rem !default;
  $menu-elevation-shadow:       $elevation-shadow-8dp !default;
  $menu-line-height:            1.5rem !default;
  $menu-line-height-cascading:  $menu-line-height !default;
  $menu-link-bg:                transparent !default;
  $menu-link-bg-active:         $offwhite !default;
  $menu-link-color:             $black-primary !default;
  $menu-link-color-disabled:    $black-hint !default;
  $menu-link-height:            3rem !default;
  $menu-link-height-cascading:  2rem !default;
  $menu-link-padding:           (($menu-link-height - $menu-line-height) / 2) $spacer-md-x !default;
  $menu-link-padding-cascading: (($menu-link-height-cascading - $menu-line-height-cascading) / 2) $spacer-lg-x !default;
  $menu-min-width:              7rem !default;
  $menu-min-width-cascading:    20rem !default;
  $menu-padding-y:              $spacer-sm-y !default;
  $menu-padding-y-cascading:    $spacer-md-y !default;

//
// navigation drawer
// based on https://material.google.com/patterns/navigation-drawer.html#navigation-drawer-specs
//
  $navdrawer-backdrop-bg:             $dialog-backdrop-bg !default;
  $navdrawer-border-color:            $border-color !default;
  $navdrawer-border-width:            $border-width !default;
  $navdrawer-content-bg:              $dialog-content-bg !default;
  $navdrawer-divider-bg:              $black-divider !default;
  $navdrawer-elevation-shadow:        $elevation-shadow-16dp !default;
  $navdrawer-gutter-width:            $toolbar-height !default;
  $navdrawer-header-bg:               $offwhite !default;
  $navdrawer-header-border-color:     $border-color !default;
  $navdrawer-header-border-width:     $border-width !default;
  $navdrawer-inner-spacer-x:          $spacer-md-x !default;
  $navdrawer-inner-spacer-y:          $spacer-sm-y !default;
  $navdrawer-nav-icon-color:          $black-secondary !default;
  $navdrawer-nav-icon-width:          $toolbar-height !default;
  $navdrawer-nav-link-bg-active:      $offwhite !default;
  $navdrawer-nav-link-color:          $black-primary !default;
  $navdrawer-nav-link-color-active:   $brand-color !default;
  $navdrawer-nav-link-color-disabled: $black-hint !default;
  $navdrawer-nav-link-font-size:      0.875rem !default;
  $navdrawer-nav-link-font-weight:    $font-weight-medium !default;
  $navdrawer-nav-link-height:         3rem !default;
  $navdrawer-subheader-color:         $black-hint !default;
  $navdrawer-subheader-font-size:     0.875rem !default;
  $navdrawer-subheader-font-weight:   $font-weight-medium !default;
  $navdrawer-subheader-height:        3rem !default;
  $navdrawer-width-mobile:            17.5rem !default;
  $navdrawer-width-tablet:            20rem !default;

//
// picker
// based on https://material.google.com/components/pickers.html#pickers-date-pickers
//
  $picker-cell-size:          2.5rem !default;
  $picker-content-bg:         $dialog-content-bg !default;
  $picker-day-bg-selected:    $brand-color !default;
  $picker-day-color-disabled: $black-hint !default;
  $picker-day-color-selected: $brand-text-color !default;
  $picker-day-color-today:    $brand-color !default;
  $picker-elevation-shadow:   $dialog-elevation-shadow !default;
  $picker-header-bg:          $brand-color !default;
  $picker-header-color:       $brand-text-color !default;
  $picker-header-padding:     $spacer-md-y $spacer-lg-x !default;
  $picker-holder-bg:          $dialog-backdrop-bg !default;
  $picker-inner-spacer-x:     $spacer-sm-x !default;
  $picker-inner-spacer-y:     $spacer-sm-y !default;
  $picker-margin-x:           $dialog-margin-x !default;
  $picker-margin-y:           $dialog-margin-y !default;
  $picker-nav-next-icon:      "keyboard_arrow_right" !default;
  $picker-nav-prev-icon:      "keyboard_arrow_left" !default;
  $picker-weekday-color:      $black-hint !default;

//
// progress
// based on https://material.google.com/components/progress-activity.html#progress-activity-types-of-indicators
//
  $progress-bar-bg:                 $palette-indigo-500 !default;
  $progress-bar-mask:               $white-secondary !default;
  $progress-height:                 0.25rem !default;

  // circular
  $progress-circular-bg:            $progress-bar-bg !default;
  $progress-circular-height:        2.5rem !default;
  $progress-circular-spinner-width: 0.25rem !default;

//
// selection control
// based on https://material.google.com/components/selection-controls.html
//
  $selection-control-checkbox-bg:                  url("data:image/svg+xml;base64,77u/PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iODM1IDczMCAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGQ9Ik0gODM1LjUgNzMwLjUgIEwgODU0LjUgNzMwLjUgIEwgODU0LjUgNzQ5LjUgIEwgODM1LjUgNzQ5LjUgIEwgODM1LjUgNzMwLjUgIFogIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0ibm9uZSIgLz4NCiAgPHBhdGggZD0iTSA4MzUuNSA3MzAuNSAgTCA4NTQuNSA3MzAuNSAgTCA4NTQuNSA3NDkuNSAgTCA4MzUuNSA3NDkuNSAgTCA4MzUuNSA3MzAuNSAgWiAiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSIjYzUwMDg0IiBmaWxsPSJub25lIiAvPg0KPC9zdmc+")  !default;  
  $selection-control-checkbox-bg-checked:          url("data:image/svg+xml;base64,77u/PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iODM1IDc2MSAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGQ9Ik0gODM1LjUgNzYxLjUgIEwgODU0LjUgNzYxLjUgIEwgODU0LjUgNzgwLjUgIEwgODM1LjUgNzgwLjUgIEwgODM1LjUgNzYxLjUgIFogIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0ibm9uZSIgLz4NCiAgPHBhdGggZD0iTSA4MzUuNSA3NjEuNSAgTCA4NTQuNSA3NjEuNSAgTCA4NTQuNSA3ODAuNSAgTCA4MzUuNSA3ODAuNSAgTCA4MzUuNSA3NjEuNSAgWiAiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSIjYzUwMDg0IiBmaWxsPSJub25lIiAvPg0KICA8cGF0aCBkPSJNIDgzOS4yODU3MTQyODU3MTQgNzcxICBMIDg0My41NzE0Mjg1NzE0MjkgNzc1LjI4NTcxNDI4NTcxNCAgTCA4NTAuNzE0Mjg1NzE0Mjg2IDc2NS4yODU3MTQyODU3MTQgICIgc3Ryb2tlLXdpZHRoPSI0LjI4NTcxNDI4NTcxNDI5IiBzdHJva2U9IiNjNTAwODQiIGZpbGw9Im5vbmUiIC8+DQo8L3N2Zz4=")  !default;
  $selection-control-checkbox-bg-disabled:         url("data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjIwcHgiIHZpZXdCb3g9IjgzNSA3MzAgIDIwIDIwIj4KICA8cGF0aCBkPSJNIDgzNS41IDczMC41ICBMIDg1NC41IDczMC41ICBMIDg1NC41IDc0OS41ICBMIDgzNS41IDc0OS41ICBMIDgzNS41IDczMC41ICBaICIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjZmZmZmZmIiBzdHJva2U9Im5vbmUiLz4KICA8cGF0aCBkPSJNIDgzNS41IDczMC41ICBMIDg1NC41IDczMC41ICBMIDg1NC41IDc0OS41ICBMIDgzNS41IDc0OS41ICBMIDgzNS41IDczMC41ICBaICIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiNiZGJkYmQiIGZpbGw9Im5vbmUiLz4KPC9zdmc+") !default;
  $selection-control-checkbox-bg-disabled-checked: url("data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjIwcHgiIHZpZXdCb3g9IjgzNSA3NjEgIDIwIDIwIj4KICA8cGF0aCBkPSJNIDgzNS41IDc2MS41ICBMIDg1NC41IDc2MS41ICBMIDg1NC41IDc4MC41ICBMIDgzNS41IDc4MC41ICBMIDgzNS41IDc2MS41ICBaICIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjZmZmZmZmIiBzdHJva2U9Im5vbmUiLz4KICA8cGF0aCBkPSJNIDgzNS41IDc2MS41ICBMIDg1NC41IDc2MS41ICBMIDg1NC41IDc4MC41ICBMIDgzNS41IDc4MC41ICBMIDgzNS41IDc2MS41ICBaICIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiNiZGJkYmQiIGZpbGw9Im5vbmUiLz4KICA8cGF0aCBkPSJNIDgzOS4yODU3MTQyODU3MTQgNzcxICBMIDg0My41NzE0Mjg1NzE0MjkgNzc1LjI4NTcxNDI4NTcxNCAgTCA4NTAuNzE0Mjg1NzE0Mjg2IDc2NS4yODU3MTQyODU3MTQgICIgc3Ryb2tlLXdpZHRoPSI0LjI4NTcxNDI4NTcxNDI5IiBzdHJva2U9IiNiZGJkYmQiIGZpbGw9Im5vbmUiLz4KPC9zdmc+") !default;
  $selection-control-checkbox-bg-indeterminate:    url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.54' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0 0h24v24H0z' id='a'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse overflow='visible' xlink:href='#a'/%3E%3C/clipPath%3E%3Cpath clip-path='url(#b)' d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z'/%3E%3C/svg%3E") !default;
  $selection-control-color:                        $black-secondary !default;
  $selection-control-color-active:                 $brand-color-accent !default;
  $selection-control-color-disabled:               rgba(0, 0, 0, 0.26) !default;
  $selection-control-indicator-size:               $material-icon-size !default;
  $selection-control-radial-bg:                    $black-divider !default;
  $selection-control-radio-bg:                     url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.54' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $selection-control-radio-bg-checked:             url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23a30054' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $selection-control-radio-bg-disabled:            url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.26' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $selection-control-radio-bg-disabled-checked:    url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23000000' fill-opacity='0.26' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !default;
  $selection-control-spacer-x:                     ($material-icon-size / $font-size-root / 2 * 1rem) !default;
  $selection-control-spacer-y:                     ($material-icon-size / $font-size-root / 2 * 1rem) !default;
  $selection-control-thumb-bg:                     $offwhite-light !default;
  $selection-control-thumb-bg-disabled:            #bdbdbd !default;
  $selection-control-thumb-shadow:                 0 1px 5px 0 rgba(0, 0, 0, 0.6) !default;
  $selection-control-track-bg:                     $black-hint !default;
  $selection-control-track-bg-disabled:            $black-divider !default;
  $selection-control-track-height:                 $font-size-root !default;
  $selection-control-track-width:                  ($selection-control-indicator-size * 2) !default;

//
// stepper
// based on https://material.google.com/components/steppers.html#steppers-specs
//
  $stepper-bg:                $white-primary !default;
  $stepper-border-color:      $palette-grey-400 !default;
  $stepper-border-width:      $border-width !default;
  $stepper-icon-bg:           $black-hint !default;
  $stepper-icon-bg-active:    $brand-color !default;
  $stepper-icon-color:        $white-primary !default;
  $stepper-icon-color-active: $brand-text-color !default;
  $stepper-icon-font-size:    0.75rem !default;
  $stepper-icon-font-weight:  $font-weight-regular !default;
  $stepper-icon-height:       ($material-icon-size / $font-size-root * 1rem) !default;
  $stepper-inner-spacer:      $spacer-sm !default;
  $stepper-padding-x:         $spacer-lg-x !default;
  $stepper-padding-y:         $spacer-lg-y !default;
  $stepper-text-color:        $black-hint !default;
  $stepper-text-color-active: $black-primary !default;
  $stepper-text-font-size:    0.875rem !default;
  $stepper-text-font-weight:  $font-weight-regular !default;

//
// tab (nav-tab)
// based on https://material.google.com/components/tabs.html#tabs-specs
//
  $nav-tab-border-color:         $black-divider !default;
  $nav-tab-indicator-bg:         #c50084 !default;
  $nav-tab-indicator-height:     2px !default;
  $nav-tab-link-font-size:       0.875rem !default;
  $nav-tab-link-font-weight:     $font-weight-medium !default;
  $nav-tab-link-height:          3rem !default;
  $nav-tab-link-opacity:         0.7 !default;
  $nav-tab-link-opacity-visible: 1 !default;
  $nav-tab-link-max-width:       16.5rem !default;
  $nav-tab-link-min-width:       4.5rem !default;
  $nav-tab-link-min-width-lg:    10rem !default;
  $nav-tab-link-padding-x:       0.75rem !default;
  $nav-tab-link-padding-x-lg:    1.5rem !default;

//
// text field (form)
// based on https://material.google.com/components/text-fields.html#text-fields-single-line-text-field
//
  $textfield-border-color:       $border-color !default;
  $textfield-border-color-focus: $brand-color !default;
  $textfield-border-width:       $border-width !default;
  $textfield-color:              $black-primary !default;
  $textfield-color-disabled:     $black-hint !default;
  $textfield-hint-color:         $black-hint !default;

  // size
  $textfield-font-size:          1rem !default;
  $textfield-font-size-lg:       2.125rem !default;
  $textfield-font-size-sm:       0.8125rem !default;
  $textfield-height:             2.25rem !default;
  $textfield-height-lg:          3.75rem !default;
  $textfield-height-sm:          2rem !default;
  $textfield-margin-bottom:      0.5rem !default;
  $textfield-margin-bottom-lg:   0.75rem !default;
  $textfield-margin-bottom-sm:   0.25rem !default;
  $textfield-padding-bottom:     0.5rem !default;
  $textfield-padding-bottom-lg:  0.5rem !default;
  $textfield-padding-bottom-sm:  0.5rem !default;
  $textfield-padding-top:        0.5rem !default;
  $textfield-padding-top-lg:     0.5rem !default;
  $textfield-padding-top-sm:     0.25rem !default;

//
// text field: floating label (form: floating label)
// based on https://material.google.com/components/text-fields.html#text-fields-labels
//
  $floating-label-color:              $textfield-hint-color !default;
  $floating-label-color-focus:        $brand-color !default;
  $floating-label-font-size-focus:    0.75rem !default;

  // size
  $floating-label-divider-padding:    0.5rem !default;
  $floating-label-divider-padding-lg: 0.75rem !default;
  $floating-label-divider-padding-sm: 0.25rem !default;
  $floating-label-height:             4rem !default;
  $floating-label-height-lg:          5.25rem !default;
  $floating-label-height-sm:          3.5rem !default;
  $floating-label-padding-top:        1rem !default;
  $floating-label-padding-top-lg:     1.25rem !default;
  $floating-label-padding-top-sm:     0.75rem !default;

//
// tooltip
// based on https://material.google.com/components/tooltips.html#tooltips-tooltips-desktop
//
  $tooltip-bg:              $palette-grey-700 !default;
  $tooltip-color:           $white-primary !default;
  $tooltip-font-size:       0.875rem !default;
  $tooltip-font-size-md-up: 0.625rem !default;
  $tooltip-height:          2rem !default;
  $tooltip-height-md-up:    1.375rem !default;
  $tooltip-margin:          $spacer-lg-y !default;
  $tooltip-margin-md-up:    0.875rem !default;
  $tooltip-opacity:         0.9 !default;
  $tooltip-padding-x:       $spacer-md-x !default;
  $tooltip-padding-x-md-up: $spacer-sm-x !default;
  $tooltip-padding-y:       (($tooltip-height - $line-height-base * $tooltip-font-size) / 2) !default;
  $tooltip-padding-y-md-up: (($tooltip-height-md-up - $line-height-base * $tooltip-font-size-md-up) / 2) !default;
  $tooltip-zindex:          $elevation-24dp !default;

//
// waves
//
  $waves-bg:       $black-hint !default;
  $waves-bg-light: $white-hint !default;
  $waves-size:     4rem !default;
