ul.list-2-col {
  column-count: 2;
}

ul.list-sm-three-col {
  @include media-breakpoint-up(sm) {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

