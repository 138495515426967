//
// make column
//
  // col
    @mixin make-col($size) {
      flex: 0 0 percentage($size / $grid-columns);
      max-width: percentage($size / $grid-columns);
    }

  // gutter
    @mixin make-gutters($type: padding) {
      @each $breakpoint in map-keys($grid-gutter-widths) {
        @include media-breakpoint-up($breakpoint) {
          $gutter: map-get($grid-gutter-widths, $breakpoint);

          @if ($type == margin) {
            margin-right: ($gutter / -2);
            margin-left: ($gutter / -2);
          } @else if ($type == padding) {
            padding-right: ($gutter / 2);
            padding-left: ($gutter / 2);
          }
        }
      }
    }

  // modifier
    @mixin make-col-modifier($size, $type) {
      @if ($type == offset) {
        @include make-col-offset($size);
      } @else if ($type == pull) {
        @include make-col-pull($size);
      } @else if ($type == push ){
        @include make-col-push($size);
      }
    }

    @mixin make-col-offset($size) {
      margin-left: percentage($size / $grid-columns);
    }

    @mixin make-col-pull($size) {
      right: if($size > 0, percentage($size / $grid-columns), auto);
    }

    @mixin make-col-push($size) {
      left: if($size > 0, percentage($size / $grid-columns), auto);
    }

//
// make container
//
  @mixin make-container() {
    margin-right: auto;
    margin-left: auto;

    @include make-gutters;
  }

  @mixin make-container-max-widths() {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint) {
        max-width: 100%;
        width: $container-max-width;
      }
    }
  }

//
// make row
//
  @mixin make-row() {
    display: flex;
    flex-wrap: wrap;

    @include make-gutters(margin);
  }
