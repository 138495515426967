@mixin sr-only {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

@mixin sr-only-focusable {
  // active, focus, hover
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      justify-content: center;
      background-color: $white;
      overflow: visible;
      position: static;
      width: auto;
    }
}
