// a base value used to responsively scale all typography, applied to the `<html>` element.
  $font-size-root:            16px !default;

// font face
// based on https://material.google.com/style/typography.html#typography-typeface
  $font-family-monospace:     Arial, Helvetica, sans-serif !default !default;
  $font-family-sans-serif:    Arial, Helvetica, sans-serif !default !default;
  $font-family-serif:         Arial, Helvetica, sans-serif !default;

  $font-family-base:          $font-family-sans-serif !default;

  $font-weight-black:         900 !default; // not used
  $font-weight-bold:          700 !default; // not used
  $font-weight-medium:        500 !default;
  $font-weight-regular:       400 !default;
  $font-weight-light:         300 !default;
  $font-weight-thin:          100 !default; // not used

  $font-weight-base:          $font-weight-regular !default;

// font styles
// based on https://material.google.com/style/typography.html#typography-styles
  $font-size-base:            0.875rem !default;  // 14px
  $font-size-old-base:        0.875rem !default;  // 14px

  $font-size-display-4:       7rem !default;      // 112px
  $font-size-display-3:       3.5rem !default;    // 56px
  $font-size-display-2:       2.8125rem !default; // 45px
  $font-size-display-1:       2.125rem !default;  // 34px
  $font-size-headline:        1.5rem !default;    // 24px
  $font-size-title:           1.25rem !default;   // 20px
  $font-size-bmj:             1.125rem !default;  // 18px
  $font-size-heading-xs:      1.125rem !default;  // 18px
  $font-size-heading-xxs:     1.0625rem !default;   // 17px
  $font-size-subheading:      1rem !default;      // 16px
  $font-size-body-2:          0.875rem !default;  // 14px
  $font-size-body-1:          0.875rem !default;  // 14px
  $font-size-caption:         0.75rem !default;   // 12px

  $font-weight-display-4:     $font-weight-light !default;
  $font-weight-display-3:     $font-weight-regular !default;
  $font-weight-display-2:     $font-weight-regular !default;
  $font-weight-display-1:     $font-weight-regular !default;
  $font-weight-headline:      $font-weight-regular !default;
  $font-weight-title:         $font-weight-medium !default;
  $font-weight-heading-xs:    $font-weight-regular !default;
  $font-weight-heading-xxs:   $font-weight-regular !default;
  $font-weight-subheading:    $font-weight-regular !default;
  $font-weight-body-2:        $font-weight-medium !default;
  $font-weight-body-1:        $font-weight-regular !default;
  $font-weight-caption:       $font-weight-regular !default;

  $letter-spacing-display-4:  -0.04em !default;
  $letter-spacing-display-3:  -0.02em !default;
  $letter-spacing-display-2:  0 !default;
  $letter-spacing-display-1:  0 !default;
  $letter-spacing-headline:   0 !default;
  $letter-spacing-title:      0 !default;
  $letter-spacing-subheading: 0.04em !default;
  $letter-spacing-heading-xs: 0 !default;
  $letter-spacing-heading-xxs:0 !default;
  $letter-spacing-body-2:     0 !default;
  $letter-spacing-body-1:     0 !default;
  $letter-spacing-caption:    0 !default;

  $nav-tabs-letters-on: 0.0rem !default;
  $nav-tabs-letters-off: 0.05rem !default;

// line height
// based on https://material.google.com/style/typography.html#typography-line-height
  $line-height-base:          1.428572 !default;

  $line-height-display-4:     7rem !default;      // 112px
  $line-height-display-3:     3.625rem !default;  // 58px
  $line-height-display-2:     3rem !default;      // 48px
  $line-height-display-1:     2.5rem !default;    // 40px
  $line-height-headline:      2rem !default;      // 32px
  $line-height-title:         1.75rem !default;   // 28px
  $line-height-subheading:    1.5rem !default;    // 24px
  $line-height-heading-xs:    1.5rem !default;    // 24px
  $line-height-heading-xxs:   1.5rem !default;    // 24px
  $line-height-body-2:        1.25rem !default;   // 20px
  $line-height-body-1:        1.25rem !default;   // 20px
  $line-height-caption:       1.125rem !default;  // 18px

// default material icons font size
  $material-icon-size:        24px !default;

// 7 Simple font sizing variables with 2px increments (12px to 24px)
  $font-size-xxl: 1.5rem;   // 24px
  $font-size-xl:  1.375rem; // 22px
  $font-size-l:   1.25rem;  // 20px
  $font-size-m:   1.125rem; // 18px
  $font-size-s:   1rem;     // 16px
  $font-size-xs:  0.875rem; // 14px
  $font-size-xxs: 0.75rem;  // 12px
