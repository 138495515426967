$grid-columns:              12 !default;
$grid-gutter-width-base:    16px !default;
$grid-gutter-width-desktop: 24px !default;

$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-desktop,
  lg: $grid-gutter-width-desktop,
  xl: $grid-gutter-width-desktop
) !default;

//
// breakpoint
// these were based on Material Design but I reset to Bootsrap norms
//
  $grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
  ) !default;

  @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
  @include _assert-starts-at-zero($grid-breakpoints);

//
// container
// these were based on Material Design but I reset to Bootsrap norms
//
  $container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
  ) !default;

  @include _assert-ascending($container-max-widths, "$container-max-widths");
