.nav {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: $nav-link-padding;

  // active, focus, hover
    @include active-focus-hover {
      text-decoration: none;
    }

  // disabled
    &.disabled {
      color: $nav-link-color-disabled;
      cursor: $cursor-disabled;
    }
}

//
// justified
//
  .nav-fill {
    .nav-item {
      flex: 1 1 auto;
      text-align: center;
    }
  }

  .nav-justified {
    .nav-item {
      flex: 1 1 100%;
      text-align: center;
    }
  }

//
// pills
//
  .nav-pills {
    .nav-link {
      color: $nav-link-color;
      @include border-radius($border-radius);
      @include transition-standard(background-color, color);

      // active, focus, hover
        @include active-focus-hover {
          color: $nav-link-color-active;
        }

        &.active {
          background-color: $nav-link-bg-active;
          color: $nav-link-color-active;
          cursor: default;
        }

      // disabled
        &.disabled {
          color: $nav-link-color-disabled;
        }
    }

    // open
      .nav-item.show .nav-link {
        background-color: $nav-link-bg-active;
        color: $nav-link-color-active;
        cursor: default;
      }
  }

//
// tabs are defined in material design guidelines
// (https://material.google.com/components/tabs.html)
// therefore, tabs are styled in /assets/sass/material/_tab.scss
//
  .tab-content {
    // hide to start
      > .tab-pane {
        display: none;
      }

    // show when activated
      > .active {
        display: block;
      }
  }

  .tab-pane {
    &.fade {
      transform: none;
    }
  }
