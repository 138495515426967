@mixin floating-label-size($font-size, $height, $padding-top, $padding-bottom) {
  $floating-label-line-height:      ($height - $padding-top - $padding-bottom);
  $floating-label-scale-percentage: ($floating-label-font-size-focus / $font-size);

  padding-top: ($floating-label-line-height * $floating-label-scale-percentage);

  > label {
    font-size: $font-size;
    line-height: $floating-label-line-height;
    // position
      top: ($floating-label-line-height * $floating-label-scale-percentage + $padding-top);
  }

  &.has-value > label,
  &.is-focused > label {
    transform: scale($floating-label-scale-percentage);
  }
}

@mixin form-control-size($border-width, $font-size, $height, $padding-top, $padding-bottom) {
  font-size: $font-size;
  height: $height;
  line-height: ($height - $padding-top - $padding-bottom);
  padding: $padding-top 0 ($padding-bottom - $border-width / $font-size-root * 1rem);

  @if $border-width != 0px {
    &:focus {
      padding-bottom: ($padding-bottom - ($border-width + 1) / $font-size-root * 1rem);
    }
  }
}

@mixin form-control-validation($color) {
  .col-form-label,
  .custom-control,
  .form-check-label,
  .form-control-feedback,
  .form-control-label {
    color: $color;
  }

  .form-control {
    border-bottom-color: $color;
  }
}
