.img-fluid {
  @include img-fluid;
}

.img-thumbnail {
  box-shadow: $thumbnail-box-shadow;
  @include img-fluid;
}


.figure-img {
  line-height: 1;
  margin-bottom: $figure-img-margin-bottom;
}
